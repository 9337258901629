import { createAction } from '@reduxjs/toolkit'

import { ResponseError } from '@marketplace-web/shared/api-client'
import { EscrowPaymentResp } from 'types/api'

import { stateName } from './constants'

export const initiateEscrowPaymentRequest = createAction(
  `${stateName}/initiateEscrowPaymentRequest`,
)

export const initiateRetryPaymentAfterSubmitCvv = createAction<{ cvv: string }>(
  `${stateName}/initiateRetryPaymentAfterSubmitCvv`,
)

// TODO: rename this action since it is used in a broader scope than payment initiation.
export const handlePaymentInitiationResponse = createAction<{
  response: EscrowPaymentResp | ResponseError
}>(`${stateName}/handlePaymentInitiationResponse`)

export const handlePendingPayment = createAction<{
  transactionId: number
  pollAttemptCount: number
}>(`${stateName}/handlePendingPayment`)

export const handlePaymentRedirectResult = createAction<{
  transactionId: number
}>(`${stateName}/handlePaymentRedirectResult`)

export const getEscrowPaymentDataRequest = createAction<{
  transactionId: number
}>(`${stateName}/getEscrowPaymentDataRequest`)

export const updateEscrowPaymentDataRequest = createAction<{
  paymentDetails: unknown
  paymentData: string
}>(`${stateName}/updateEscrowPaymentDataRequest`)

export const validateFields = createAction(`${stateName}/validateFields`)

export const fetchConfigurationRequest = createAction(`${stateName}/fetchConfigurationRequest`)
