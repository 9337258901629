import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState as UiStateEnum } from '@marketplace-web/shared/ui-helpers'
import { CoordinatesModel, CountryBoundsModel, ShippingPointModel } from 'types/models'
import { PickupPointModel } from 'types/models/pickup-point'
import { ShippingOptionModel } from 'types/models/shipping-option'
import { PickupModalElement } from 'constants/checkout'

import { stateName } from '../constants'

import { ShippingState, UiState } from './types'

export const initialPickupModalUiState = {
  [PickupModalElement.Modal]: UiStateEnum.Idle,
  [PickupModalElement.SearchArea]: UiStateEnum.Idle,
  [PickupModalElement.UserLocation]: UiStateEnum.Idle,
  [PickupModalElement.Search]: UiStateEnum.Idle,
}

export const initialDeliveryUiState = {
  deliveryTypes: UiStateEnum.Idle,
}

export const initialUiState: UiState = {
  pickupModal: initialPickupModalUiState,
  pickupPoints: UiStateEnum.Idle,
}

export const initialState: ShippingState = {
  countryBounds: null,
  nearbyPoints: [],
  ui: initialUiState,
  suggestedShippingPointCode: null,
  selectedShippingPoint: null,
  shippingOptions: [],
}

const fetchCountryBoundsSuccess: CaseReducer<
  ShippingState,
  PayloadAction<{ countryBounds: CountryBoundsModel }>
> = (draft, action) => {
  const { countryBounds } = action.payload

  draft.countryBounds = countryBounds
}

const fetchNearbyShippingPointsRequest: CaseReducer<
  ShippingState,
  PayloadAction<{
    coordinates?: CoordinatesModel
    shouldLabelNearestPoints?: boolean
  }>
> = draft => {
  draft.ui.pickupModal = initialPickupModalUiState
  draft.ui.pickupPoints = UiStateEnum.Pending
  draft.selectedShippingPoint = null
}

const fetchNearbyShippingOptionsFailure: CaseReducer<ShippingState> = draft => {
  draft.nearbyPoints = []
  draft.ui.pickupPoints = UiStateEnum.Failure
}

const setPickupModalElementUiState: CaseReducer<
  ShippingState,
  PayloadAction<{ element: PickupModalElement; uiState: UiStateEnum }>
> = (draft, action) => {
  const { element, uiState } = action.payload

  draft.ui.pickupModal[element] = uiState
}

const setSelectedShippingPoint: CaseReducer<
  ShippingState,
  PayloadAction<{
    selectedShippingPoint: ShippingPointModel | null
  }>
> = (draft, action) => {
  const { selectedShippingPoint } = action.payload

  draft.selectedShippingPoint = selectedShippingPoint
}

const resetPickupPointsUiState: CaseReducer<ShippingState> = draft => {
  draft.ui.pickupPoints = UiStateEnum.Idle
}

// an edge case when changing physical authenticity flag displays stale data in the pickup map
const resetShippingOptions: CaseReducer<ShippingState> = draft => {
  draft.ui.pickupModal = initialPickupModalUiState
  draft.ui.pickupPoints = UiStateEnum.Idle
  draft.countryBounds = null
  draft.nearbyPoints = []
  draft.suggestedShippingPointCode = null
  draft.selectedShippingPoint = null
  draft.shippingOptions = []
}

const fetchNearbyShippingOptionsSuccess: CaseReducer<
  ShippingState,
  PayloadAction<{
    nearbyShippingPoints: Array<PickupPointModel>
    nearbyShippingOptions: Array<ShippingOptionModel>
    suggestedShippingPointCode: string | null
    suggestedRateUuid?: string
  }>
> = (draft, action) => {
  const {
    nearbyShippingPoints,
    nearbyShippingOptions,
    suggestedShippingPointCode,
    suggestedRateUuid,
  } = action.payload

  draft.nearbyPoints = nearbyShippingPoints
  draft.shippingOptions = nearbyShippingOptions
  draft.suggestedShippingPointCode = suggestedShippingPointCode
  draft.suggestedRateUuid = suggestedRateUuid
  draft.ui.pickupPoints = UiStateEnum.Success
}

const shippingSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchCountryBoundsSuccess,
    fetchNearbyShippingPointsRequest,
    fetchNearbyShippingOptionsFailure,
    setPickupModalElementUiState,
    setSelectedShippingPoint,
    resetPickupPointsUiState,
    fetchNearbyShippingOptionsSuccess,
    resetShippingOptions,
  },
})

export const { actions } = shippingSlice
export const plug = { [stateName]: shippingSlice.reducer }

export default shippingSlice.reducer
